import React from "react";
import { createLucideIcon } from 'lucide-react'

const Icon = createLucideIcon("EmsAdminLogo", [
  ["path", {
    key: "ems-admin-logo-path-1",
    d: "M9.4 14.2996L7.8 24.1996L4.7 20.7996H24.1V27.0996H0L1.9 14.3996L0 1.59961H24V7.89961H4.8L7.9 4.49961L9.5 14.3996L9.4 14.2996ZM23 11.4996V17.0996H4V11.4996H22.9H23Z"
  }],
  ["path", {
    key: "ems-admin-logo-path-2",
    d: "M66.2 1.59961V27.0996H58.7V4.49961L60.3 4.69961L51.1 27.0996H43.2L33.9 4.69961L35.5 4.49961V26.9996H28V1.59961H40.2L48.6 22.8996H45.7L54 1.59961H66.2Z"
  }],
  ["path", {
    key: "ems-admin-logo-path-3",
    d: "M69.6 18.6998H77.6C77.6 19.2998 78 19.7998 78.5 20.1998C79 20.5998 79.6 20.9998 80.5 21.1998C81.3 21.3998 82.3 21.5998 83.5 21.5998C84.7 21.5998 86.3 21.3998 87.2 21.0998C88.1 20.6998 88.5 20.1998 88.5 19.3998C88.5 18.5998 88.3 18.4998 87.8 18.0998C87.3 17.6998 86.3 17.5998 84.8 17.3998L80.3 16.9998C76.6 16.6998 74 15.8998 72.3 14.5998C70.7 13.2998 69.8 11.4998 69.8 9.1998C69.8 6.8998 70.3 5.7998 71.4 4.5998C72.5 3.3998 73.9 2.3998 75.9 1.7998C77.9 1.1998 80 0.799805 82.6 0.799805C85.2 0.799805 87.3 1.1998 89.2 1.8998C91.1 2.5998 92.7 3.5998 93.8 4.8998C94.9 6.1998 95.6 7.6998 95.7 9.3998H87.7C87.7 8.8998 87.4 8.4998 86.9 8.0998C86.5 7.6998 85.9 7.3998 85.1 7.1998C84.3 6.9998 83.4 6.8998 82.3 6.8998C81.2 6.8998 79.8 7.0998 79 7.3998C78.2 7.6998 77.8 8.19981 77.8 8.89981C77.8 9.5998 78 9.7998 78.5 10.0998C79 10.3998 79.9 10.5998 81.2 10.6998L86.1 11.0998C88.5 11.2998 90.5 11.6998 92 12.1998C93.5 12.7998 94.6 13.5998 95.3 14.4998C96 15.3998 96.3 16.6998 96.3 18.1998C96.3 19.6998 95.8 21.5998 94.7 22.9998C93.6 24.3998 92.1 25.3998 90.1 26.1998C88.1 26.9998 85.8 27.2998 83.2 27.2998C80.6 27.2998 78.1 26.8998 76.1 26.1998C74.1 25.4998 72.5 24.3998 71.3 23.0998C70.1 21.7998 69.5 20.1998 69.5 18.3998L69.6 18.6998Z"
  }],
  ["path", {
    key: "ems-admin-logo-path-4",
    d: "M152.6 18.5C152.3 20.3 151.7 21.9 150.9 23.3C150.1 24.6 149 25.7 147.7 26.5C146.4 27.3 145 27.6 143.4 27.6C141.8 27.6 140.2 27.2 139 26.5C137.7 25.7 136.7 24.7 136 23.3C135.3 22 134.9 20.4 134.9 18.6C134.9 16.8 135.3 15.2 136 13.9C136.7 12.6 137.7 11.5 139 10.7C140.3 9.9 141.7 9.6 143.4 9.6C145.1 9.6 147.3 10.1 148.6 10.8C152.5 12.8 152.3 16.8 152.6 18.7V18.5ZM140.6 18.5C140.6 19.4 140.8 20.2 141.2 20.9C141.6 21.6 142.1 22.2 142.8 22.6C143.5 23 144.2 23.2 145.1 23.2C146 23.2 146.8 23 147.6 22.6C148.4 22.2 149.1 21.6 149.7 20.9C150.9 19.5 150.9 17.4 149.7 16.1C149.1 15.4 148.4 14.8 147.6 14.4C146.8 14 146 13.8 145.1 13.8C144.2 13.8 143.4 14 142.8 14.4C142.1 14.8 141.6 15.4 141.2 16.1C140.8 16.8 140.6 17.6 140.6 18.5ZM151.7 27.1V18.6V4H157.5V27.1H151.7Z"
  }],
  ["path", {
    key: "ems-admin-logo-path-5",
    d: "M160.6 10H165.9V16.7C166 16.7 166 27.1 166 27.1H160.4V10H160.6ZM172.1 9.5C173.7 9.5 175 9.8 176.1 10.5C177.2 11.2 178 12.1 178.6 13.3C179.2 14.5 179.5 15.9 179.5 17.6V27.1H173.9V18.4C173.9 17 173.6 15.9 173 15.2C172.4 14.5 171.5 14.1 170.3 14.1C169.1 14.1 168.7 14.3 168.1 14.7C167.5 15.1 167 15.6 166.7 16.3C166.4 17 166.2 17.9 166.2 18.8L164.4 17.9C164.6 16.1 165.1 14.6 165.8 13.3C166.5 12.1 167.4 11.1 168.5 10.5C169.6 9.9 170.8 9.5 172.1 9.5ZM185.4 9.5C187 9.5 188.3 9.8 189.4 10.5C190.5 11.2 191.3 12.1 191.9 13.3C192.5 14.5 192.8 15.9 192.8 17.6V27.1H187.2V18.4C187.2 17 186.9 15.9 186.3 15.2C185.7 14.5 184.8 14.1 183.6 14.1C182.4 14.1 182 14.3 181.4 14.7C180.8 15.1 180.3 15.6 180 16.3C179.7 17 179.5 17.9 179.5 18.8L177.7 17.9C177.9 16.1 178.4 14.6 179.1 13.3C179.8 12.1 180.7 11.1 181.8 10.5C182.9 9.9 184.1 9.5 185.4 9.5Z"
  }],
  ["path", {
    key: "ems-admin-logo-path-6",
    d: "M198.9 8.19961C197.9 8.19961 197.1 7.89961 196.5 7.39961C195.9 6.89961 195.6 6.19961 195.6 5.39961C195.6 4.59961 195.9 3.89961 196.5 3.39961C197.1 2.89961 197.9 2.59961 198.9 2.59961C199.9 2.59961 200.7 2.89961 201.3 3.39961C201.9 3.89961 202.2 4.59961 202.2 5.39961C202.2 6.19961 201.9 6.89961 201.3 7.39961C200.7 7.89961 199.9 8.19961 198.9 8.19961ZM196.1 9.69961H201.7V26.9996H196.1V9.69961Z"
  }],
  ["path", {
    key: "ems-admin-logo-path-7",
    d: "M205.6 10H210.9V16.7C211 16.7 211 27.1 211 27.1H205.4V10H205.6ZM217.5 9.5C219.1 9.5 220.5 9.8 221.7 10.5C222.8 11.2 223.7 12.1 224.3 13.3C224.9 14.5 225.2 15.9 225.2 17.6V27.1H219.6V18.4C219.6 17 219.2 15.9 218.5 15.2C217.8 14.5 216.8 14.1 215.5 14.1C214.2 14.1 213.8 14.3 213.1 14.7C212.4 15.1 211.9 15.6 211.5 16.3C211.1 17 210.9 17.9 210.9 18.8L209.1 17.9C209.3 16.1 209.8 14.6 210.6 13.3C211.4 12.1 212.3 11.1 213.5 10.5C214.6 9.9 215.9 9.5 217.3 9.5H217.5Z"
  }],
  ["path", {
    key: "ems-admin-logo-path-8",
    d: "M123 1.6001L134.9 27.1001H128.8L118.4 4.0001H120.3L109.9 27.1001H103.8L115.6 1.6001H123ZM110.3 21.7001V17.0001H128V21.7001H110.3Z"
  }],
])

const EmsAdminLogo = ({
  width = 226,
  height = 28,
  ...props
}) => (
  <Icon viewBox="0 0 226 28" width={width} height={height} {...props}/>
)

export default EmsAdminLogo;