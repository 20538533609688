import React from 'react';
import { Link as LinkRR } from 'react-router-dom';
import classNames from 'classnames';
import { ChevronDownIcon } from "lucide-react";
import {
  NavbarContent,
  NavbarItem,
  Link,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import LayoutType from 'src/shared/utils/layout_type';

const Menu = ({layoutType}) => {
  const reloadFrontLink = layoutType !== LayoutType.FRONT_SITE;
  const reloadFindPartLink = layoutType !== LayoutType.FIND_PART;
  const reloadBOMCheckLink = layoutType !== LayoutType.BOM_CHECK;

  return (
    <NavbarContent className="hidden sm:flex gap-7 menu" style={{flexGrow: 0}}>
      <Dropdown>
        <DropdownTrigger>
          <NavbarItem>
            <span className={classNames("text-white font-medium flex gap-1 items-center cursor-pointer", {
              '!text-[#293538] !font-semibold': layoutType === LayoutType.BOM_QUOTE,
            })}>
              ERP <ChevronDownIcon size={16} />
            </span>
          </NavbarItem>
        </DropdownTrigger>
        <DropdownMenu aria-label="ERP" variant="flat" itemClasses={{
          base: [
            'px-0',
            'py-0',
          ],
        }}>
          <DropdownItem key="erp-projects" textValue="projects">
            <LinkRR reloadDocument={reloadFrontLink} to="/" className="px-2 py-1.5 block">
              Projects
            </LinkRR>
          </DropdownItem>
          <DropdownItem key="erp-inventory" textValue="inventory">
            <LinkRR reloadDocument={reloadFrontLink} to="/" className="px-2 py-1.5 block">
              Inventory
            </LinkRR>
          </DropdownItem>
          <DropdownItem key="erp-purchase" textValue="purchase">
            <LinkRR reloadDocument={reloadFrontLink} to="/" className="px-2 py-1.5 block">
              Purchase
            </LinkRR>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Dropdown>
        <DropdownTrigger>
          <NavbarItem>
            <span className={classNames("text-white font-medium flex gap-1 items-center cursor-pointer", {
              '!text-[#293538] !font-semibold': layoutType === LayoutType.BOM_QUOTE,
            })}>
              BOM Tools <ChevronDownIcon size={16} />
            </span>
          </NavbarItem>
        </DropdownTrigger>
        <DropdownMenu aria-label="BOM Tools" variant="flat" itemClasses={{
          base: [
            'px-0',
            'py-0',
          ],
        }}>
          <DropdownItem key="find-part" className="" textValue='find part'>
            <LinkRR reloadDocument={reloadFindPartLink} to="/find-part" className="px-2 py-1.5 block">
              Find Part
            </LinkRR>
          </DropdownItem>
          <DropdownItem key="bom-quote" className="" textValue='bom quote'>
            <LinkRR reloadDocument={reloadFrontLink} to="/bom-tool" className="px-2 py-1.5 block">
              BOM Quote
            </LinkRR>
          </DropdownItem>
          <DropdownItem key="bom-check" className="" textValue='bom check'>
            <LinkRR reloadDocument={reloadBOMCheckLink} to="/bom-check" className="px-2 py-1.5 block">
              BOM Check
            </LinkRR>
          </DropdownItem>
          <DropdownItem key="bom-compare" className="" textValue='bom compare'>
            <LinkRR reloadDocument={true} to="/bom_compare" className="px-2 py-1.5 block">
              BOM Compare
            </LinkRR>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {/*<NavbarItem>*/}
      {/*  <Link color="foreground" as={LinkRR} to="/" className="text-white font-medium" reloadDocument={reloadFrontLink}>*/}
      {/*    About Us*/}
      {/*  </Link>*/}
      {/*</NavbarItem>*/}
      <NavbarItem>
        <Link color="foreground" as={LinkRR} to="/contact-us" href="/contact-us"
              className={classNames("text-white font-medium", {
                '!text-[#293538] !font-semibold': layoutType === LayoutType.BOM_QUOTE,
              })}
              reloadDocument={reloadFrontLink}>
          Contact
        </Link>
      </NavbarItem>
    </NavbarContent>
  );
};

export default Menu;